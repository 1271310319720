export const TRANSLATIONS = {
	uk: {
		subHeaderTitle1: 'СЕРВІС ПЕРЕВІРКИ',
		subHeaderTitle2: 'ПРОДУКЦІЇ ІНТЕРПАЙП',
		subHeaderTitle3: 'Онлайн-сервіс перевірки',
		subHeaderTitle4: 'походження трубної продукції',

		captchaTitle: 'Боріться з підробками разом з ІНТЕРПАЙП',
		captchaSubTitle1: 'Маєте сумніви щодо походження труб?',
		captchaSubTitle2: 'Перевірте, чи виробник ваго замовлення ІНТЕРПАЙП!',
		checkOut: 'Перевірити',
		invalidateURL: 'Некоректне посилання',
		errorCaptcha: 'Спочатку потрібно пройти каптчу',

		allRights: 'Всі права захищені',

		errorTitle: 'Сторінку не знайдено',

		infoTitle1: 'Номер ДІЙСНИЙ.',
		infoTitle2: 'Ви придбали оригінальний продукт ІНТЕРПАЙП!',
		infoCounty: 'Країна призначення',
		infoTons: 'Кількість, тонн',
		infoInspection: 'Інспекційний сертифікат №',
		infoMelt: 'Номер плавки',
		infoShipment: 'Дата відвантаження',
		infoManufacturer: 'Виробник',
		infoCountryOfOrigin: 'Країна походження',

		notGenuineTitle: 'Номер недійсний. Ви купили підроблений продукт.',
		notGenuineSubTitle: 'Для перевірки замовлення зверніться до відділу продажів ІНТЕРПАЙП',

		loadCertificate: 'Завантажити сертифікат',
		reloadCertificate: 'Оновити сертифікат',
		openCertificate: 'Відкрити сертифікат',

		serverError: 'Виникла помилка під час виконання запиту',
		somethingWentWrongError: ' Щось пішло не так'
	},
	en: {
		subHeaderTitle1: 'VERIFICATION SERVICE',
		subHeaderTitle2: 'INTERPIPE PRODUCTS',
		subHeaderTitle3: 'Online Verification Service',
		subHeaderTitle4: 'origin of pipe products',

		captchaTitle: 'Fend off counterfeits with INTERPIPE',
		captchaSubTitle1: 'Do you have any doubts about the origin of the pipes?',
		captchaSubTitle2: 'Check the authenticity of your INTERPIPE product',
		checkOut: 'Check out',
		invalidateURL: 'Incorrect link',
		errorCaptcha: 'First you need to pass the captcha',

		allRights: 'All rights reserved',

		errorTitle: 'Page not found',

		infoTitle1: 'The number is VALID.',
		infoTitle2: 'You have purchased an original INTERPIPE product!',
		infoCounty: 'Country of destination',
		infoTons: 'Quantity, tons',
		infoInspection: 'Mill Test Certificate No.',
		infoMelt: 'Head number',
		infoShipment: 'Date of shipment',
		infoManufacturer: 'Manufacturer',
		infoCountryOfOrigin: 'Country of origin',

		notGenuineTitle: 'The number is not valid. You have bought counterfeit product.',
		notGenuineSubTitle: 'Сontact INTERPIPE Sales Department to check your order.',

		loadCertificate: 'Download MTC',
		reloadCertificate: 'Update сertificate',
		openCertificate: 'Open сertificate',

		serverError: 'An error occurred while executing the request',
		somethingWentWrongError: ' Something went wrong'
	},
	ru: {
		subHeaderTitle1: 'СЕРВИС ПРОВЕРКИ',
		subHeaderTitle2: 'ПРОДУКЦИИ ИНТЕРПАЙП',
		subHeaderTitle3: 'Онлайн-сервис проверки',
		subHeaderTitle4: 'происхождение трубной продукции',

		captchaTitle: 'Боритесь с подделками вместе с ИНТЕРПАЙП',
		captchaSubTitle1: 'Нет сомнений в происхождении труб?',
		captchaSubTitle2: 'Проверьте, произведен ли ваш заказ ИНТЕРПАЙП!',
		checkOut: 'Проверить',
		invalidateURL: 'Некорректная ссылка',
		errorCaptcha: 'Сначала нужно пройти каптчу',

		allRights: 'Все права защищены',

		errorTitle: 'Страница не найдена',

		infoTitle1: 'Номер ДЕЙСТВИТЕЛЬНЫЙ.',
		infoTitle2: 'Вы приобрели оригинальный продукт ИНТЕРПАЙП!',
		infoCounty: 'Страна назначения',
		infoTons: 'Количество, тонн',
		infoInspection: 'Инспекционный сертифікат №',
		infoMelt: 'Номер плавки',
		infoShipment: 'Дата отгрузки',
		infoManufacturer: 'Производитель',
		infoCountryOfOrigin: 'Страна происхождения',

		notGenuineTitle: 'Номер недействителен. Вы купили контрафактный товар.',
		notGenuineSubTitle: 'Обратитесь в отдел продаж ИНТЕРПАЙП для проверки вашего заказа.',

		loadCertificate: 'Загрузить сертификат',
		reloadCertificate: 'Обновить сертификат',
		openCertificate: 'Открыть сертификат',

		serverError: 'Произошла ошибка при выполнении запроса',
		somethingWentWrongError: ' Что-то пошло не так'
	}
};
