import React, { useEffect, useState } from 'react';
import './styles.scss';
import ReCAPTCHA from 'react-google-recaptcha';
import { useI18n } from '../../Hooks/useI18n';
import { http } from '../../Utils/http';
import { endpoints } from '../../Utils/http/endpoints';
import { useLocation } from 'react-router-dom';

export const Captcha = ({ setData }) => {
	const location = useLocation();

	const { i18n, currentLanguage } = useI18n();
	const [loading, setLoading] = useState(false);
	const [recaptchaValue, setRecaptchaValue] = useState(null);
	const [error, setError] = useState('');

	const id = new URLSearchParams(location.search).get('id');

	const sitekey = process.env.REACT_APP_CAPTCHA_KEY;

	useEffect(() => {
		setError('');
	}, [currentLanguage]);

	const getAuthGrid = async () => {
		if (id && !loading) {
			setLoading(true);
			http.post(endpoints.GET_DATA, {
				id
			})
				.then((response) => {
					setLoading(false);
					setError('');

					if (response.data.SUCCESS === '-') {
						setData('isNotGenuineProducts');
					} else if (response.data.PRODUCTINFO.length) {
						setData(response.data.PRODUCTINFO[0]);
					}
				})
				.catch((error) => {
					setLoading(false);
					setError(i18n('serverError'));
					console.error(error);
				});
		}
	};

	const handleRecaptchaChange = (value) => {
		setRecaptchaValue(value);
	};

	const handleClick = async () => {
		if (id) {
			if (recaptchaValue) {
				setError('');
				await getAuthGrid();
			} else {
				setError(i18n('errorCaptcha'));
			}
		} else {
			setError(`${i18n('invalidateURL')}: ${window.location.href}`);
		}
	};

	return (
		<div className={'container container--first'}>
			<div className={'captcha'}>
				<div className={'captcha__grid'}>
					<div className={'captcha__cell'}>
						<h1 className={'h1'}>{i18n('captchaTitle')}</h1>
					</div>
					<div className={'captcha__cell'}>
						<div className={'h3'}>{i18n('captchaSubTitle1')}</div>
						<div className={'h3'}>{i18n('captchaSubTitle2')}</div>
					</div>
				</div>
				<div className={`captcha__body`}>
					{loading ? (
						<div className={'loading'}></div>
					) : (
						<>
							{id ? (
								<>
									<ReCAPTCHA
										key={currentLanguage}
										hl={currentLanguage}
										sitekey={sitekey}
										onChange={handleRecaptchaChange}
									/>
									<button
										onClick={() => handleClick()}
										className={`button ${loading ? 'button--loading' : ''}`}
										disabled={!id}
									>
										{i18n('checkOut')}
									</button>
								</>
							) : (
								<div className={'h3 captcha__invalidate-url'}>
									{i18n('invalidateURL')} ({window.location.href})
								</div>
							)}

							{error ? <span className={'error'}>{error}</span> : null}
						</>
					)}
				</div>
			</div>
		</div>
	);
};
