import { Header } from '../../Components/Header';
import { Footer } from '../../Components/Footer';
import React from 'react';

export const MainLayout = ({ children }) => {
	return (
		<div className="body">
			<Header />
			<main>{children}</main>
			<Footer />
		</div>
	);
};
