import './styles.scss';
import { useLanguage } from '../../Providers/I18n';
import FlagENSvg from '../../resources/svg/flags/flag-en.svg';
import FlagUASvg from '../../resources/svg/flags/flag-ua.svg';
import FlagRUSvg from '../../resources/svg/flags/flag-ru.svg';
import { useEffect, useRef, useState } from 'react';
import { LANG_LIST } from '../../Utils/constants/langs';

const langFlags = {
	en: FlagENSvg,
	ru: FlagRUSvg,
	uk: FlagUASvg
};
export const LanguageSwitcher = () => {
	const { currentLanguage, changeLanguage } = useLanguage();
	const langRef = useRef(null);
	const [open, setOpen] = useState(false);
	const handleOutsideClick = (event) => {
		if (langRef.current && !langRef.current.contains(event.target)) {
			setOpen(false);
		}
	};

	const handleChangeLang = (lang) => {
		changeLanguage(lang);
	};

	useEffect(() => {
		document.addEventListener('click', handleOutsideClick);
		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	}, []);

	return (
		<div ref={langRef} className={`lang ${open ? 'lang--open' : ''}`} onClick={() => setOpen(!open)}>
			<div className={'lang__item'}>
				<img src={langFlags[currentLanguage]} className={'lang__flag'} alt={''} />
				<span>{currentLanguage}</span>
			</div>
			<div className={'lang__dropdown'}>
				{LANG_LIST.map((l, i) =>
					l !== currentLanguage ? (
						<div onClick={() => handleChangeLang(l)} key={l} className={'lang__item'}>
							<img src={langFlags[l]} className={'lang__flag'} alt={''} />
							<span>{LANG_LIST[i]}</span>
						</div>
					) : null
				)}
			</div>
		</div>
	);
};
