import { TRANSLATIONS } from '../Utils/constants/translations';
import { useLanguage } from '../Providers/I18n';

export const useI18n = () => {
	const { currentLanguage } = useLanguage();

	const getTranslation = (key) => {
		return TRANSLATIONS[currentLanguage][key] || key;
	};

	return { i18n: getTranslation, currentLanguage };
};
