import LogoUAWhite from '../../resources/svg/logos/logo-ua.svg';
import LogoUABlack from '../../resources/svg/logos/logo-ua--black.svg';

import LogoENWhite from '../../resources/svg/logos/logo-en.svg';
import LogoENBlack from '../../resources/svg/logos/logo-en--black.svg';

import LogoRUWhite from '../../resources/svg/logos/logo-ru.svg';
import LogoRUBlack from '../../resources/svg/logos/logo-ru--black.svg';

export const logoConfig = {
	uk: {
		white: LogoUAWhite,
		black: LogoUABlack
	},
	en: {
		white: LogoENWhite,
		black: LogoENBlack
	},
	ru: {
		white: LogoRUWhite,
		black: LogoRUBlack
	}
};
