import './styles.scss';
import React, { useState } from 'react';
import { SubHeader } from '../SubHeader';
import { Captcha } from '../Captcha';
import { MainLayout } from '../../Layouts/MainLayout';
import { Info } from '../Info';
import { NotGenuine } from '../NotGenuine';

export const App = () => {
	const [data, setData] = useState(null);

	return (
		<MainLayout>
			<SubHeader data={data} />
			{data ? (
				data !== 'isNotGenuineProducts' ? (
					<Info data={data} />
				) : (
					<NotGenuine />
				)
			) : (
				<Captcha setData={setData} />
			)}
		</MainLayout>
	);
};
