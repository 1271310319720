import './styles.scss';
import { MainLayout } from '../../Layouts/MainLayout';
import React from 'react';
import { SubHeader } from '../SubHeader';
import { useI18n } from '../../Hooks/useI18n';

export const Error = () => {
	const { i18n } = useI18n();

	return (
		<MainLayout>
			<SubHeader is404={true} />
			<div className={'container'}>
				<div className={'error-container'}>
					<h1 className={'h1'}>{i18n('errorTitle')}</h1>
				</div>
			</div>
		</MainLayout>
	);
};
