import React, { createContext, useContext, useState } from 'react';
import { LANG_LIST } from '../../Utils/constants/langs';

const LanguageContext = createContext();

export const useLanguage = () => {
	return useContext(LanguageContext);
};

export const I18nProvider = ({ children }) => {
	const [currentLanguage, setCurrentLanguage] = useState(() => {
		const locLang = window.localStorage.getItem('lang');

		return locLang ? locLang : LANG_LIST.includes(navigator.language) ? navigator.language : LANG_LIST[0];
	});

	const changeLanguage = (newLanguage) => {
		window.localStorage.setItem('lang', newLanguage);
		setCurrentLanguage(newLanguage);
	};

	return <LanguageContext.Provider value={{ currentLanguage, changeLanguage }}>{children}</LanguageContext.Provider>;
};
