import axios from 'axios';
import { endpoints } from './endpoints';

export const http = axios.create({
	baseURL: endpoints.BASE,
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json'
	}
});
