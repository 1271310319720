import FacebookIcon from '../../resources/svg/socials/facebook.svg';
import InstagramIcon from '../../resources/svg/socials/instagram.svg';
import LinkedinIcon from '../../resources/svg/socials/linkedin.svg';
import YoutubeIcon from '../../resources/svg/socials/youtube.svg';

export const contacts = {
	mail: {
		value: 'info@ua.interpipe.biz',
		label: 'info@ua.interpipe.biz'
	}
};

export const socials = {
	facebook: {
		valueEN: 'https://www.facebook.com/interpipe.en',
		valueUK: 'https://www.facebook.com/Interpipe',
		icon: FacebookIcon
	},
	instagram: {
		value: 'https://instagram.com/interpipe.ua?igshid=OGQ5ZDc2ODk2ZA==',
		icon: InstagramIcon
	},
	linkedin: {
		value: 'https://www.linkedin.com/company/interpipe/',
		icon: LinkedinIcon
	},
	youtube: {
		value: 'https://youtube.com/@Interpipe1?si=S3GN7BxXDalzT5MK',
		icon: YoutubeIcon
	}
};
