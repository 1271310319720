import './styles.scss';
import Bg from '../../resources/svg/sub-header-bg.svg';
import { useI18n } from '../../Hooks/useI18n';

export const SubHeader = ({ data, is404 = false }) => {
	const { i18n } = useI18n();

	return (
		<div className={'container'}>
			<div className={'sub-header'}>
				<div className={'sub-header__inner'}>
					<div className="sub-header__content">
						<div className={`sub-header__text ${is404 ? 'h1' : 'h2'}`}>
							{is404 ? 404 : data ? i18n('subHeaderTitle3') : i18n('subHeaderTitle1')}
							<br />
							{!is404 ? (data ? i18n('subHeaderTitle4') : i18n('subHeaderTitle2')) : null}
						</div>
					</div>
					<img className={'sub-header__bg'} src={Bg} alt={''} />
				</div>
			</div>
		</div>
	);
};
