import './styles.scss';

import { logoConfig } from '../../Utils/constants/logoConfig';
import { useI18n } from '../../Hooks/useI18n';
import { contacts, socials } from '../../Utils/constants/contacts';
import { LANG_LIST } from '../../Utils/constants/langs';

export const Footer = () => {
	const { i18n, currentLanguage } = useI18n();

	return (
		<footer className={'footer'}>
			<div className={'container'}>
				<div className="footer__inner">
					<div className={'footer__left'}>
						<img className={'footer__logo'} src={logoConfig[currentLanguage].black} alt="" />
						<div className={'footer__rights'}>
							© {new Date().getFullYear()} {i18n('allRights')}
						</div>
					</div>
					<div className={'footer__socials'}>
						<a
							href={
								currentLanguage === LANG_LIST[0] ? socials.facebook.valueEN : socials.facebook.valueUK
							}
							className={'footer__socials-item'}
						>
							<img
								src={socials.facebook.icon}
								alt={
									currentLanguage === LANG_LIST[0]
										? socials.facebook.valueEN
										: socials.facebook.valueUK
								}
							/>
						</a>
						<a href={socials.instagram.value} className={'footer__socials-item'}>
							<img src={socials.instagram.icon} alt={socials.instagram.value} />
						</a>
						<a href={socials.linkedin.value} className={'footer__socials-item'}>
							<img src={socials.linkedin.icon} alt={socials.linkedin.value} />
						</a>
						<a href={socials.youtube.value} className={'footer__socials-item'}>
							<img src={socials.youtube.icon} alt={socials.youtube.value} />
						</a>
					</div>
					<div className={'footer__contacts'}>
						<a href={`mailto:${contacts.mail.value}`} className="footer__contacts-item">
							{contacts.mail.label}
						</a>
					</div>
				</div>
			</div>
		</footer>
	);
};
