import React, { useEffect, useState } from 'react';
import './styles.scss';
import { useI18n } from '../../Hooks/useI18n';
import { http } from '../../Utils/http';
import { endpoints } from '../../Utils/http/endpoints';
import { useLocation } from 'react-router-dom';

export const Info = ({ data }) => {
	const location = useLocation();

	const { i18n, currentLanguage } = useI18n();
	const [loading, setLoading] = useState(false);
	const [certificateLink, setCertificateLink] = useState(null);
	const [error, setError] = useState(null);

	const id = new URLSearchParams(location.search).get('id');

	const dateShip = data.DSHIPP_ ? data.DSHIPP_.replace(/\/Date\((\d+)\)\//, '$1') : null;

	useEffect(() => {
		setError('');
	}, [currentLanguage]);

	const handleLoadCertificate = async () => {
		if (id && !loading) {
			setLoading(true);

			http.post(endpoints.GET_CERTIFICATE, {
				id
			})
				.then((response) => {
					setLoading(false);

					if (response.data.stringByte) {
						const binaryData = atob(response.data.stringByte);

						const arrayBuffer = new ArrayBuffer(binaryData.length);
						const uint8Array = new Uint8Array(arrayBuffer);

						for (let i = 0; i < binaryData.length; i++) {
							uint8Array[i] = binaryData.charCodeAt(i);
						}

						// Создаем Blob из массива байт
						const blob = new Blob([uint8Array], { type: 'application/pdf' });

						// Создаем URL для открытия PDF в новой вкладке
						const pdfUrl = URL.createObjectURL(blob);

						setCertificateLink(pdfUrl);
					} else {
						setLoading(false);
						setError(i18n('somethingWentWrongError'));
						console.error(response);
					}
				})
				.catch((error) => {
					setLoading(false);
					setError(i18n('serverError'));
					console.error(error);
				});
		}
	};

	return (
		<div className={'container container--first'}>
			<div className={'info'}>
				<div className={'h3 info__title'}>
					<span className={'info__title-1'}>{i18n('infoTitle1')}</span>
					<span className={'info__title-2'}>{i18n('infoTitle2')}</span>
				</div>
				{loading ? (
					<div className={'loading'}></div>
				) : (
					<div className={'info__actions'}>
						{certificateLink ? (
							<div className={'info__actions-cell'}>
								<a
									href={certificateLink}
									target={'_blank'}
									rel={'noreferrer'}
									className={`button ${loading ? 'button--loading' : ''}`}
								>
									{i18n('openCertificate')}
								</a>
							</div>
						) : (
							<div className={'info__actions-cell'}>
								<button
									onClick={() => handleLoadCertificate()}
									className={`button ${loading ? 'button--loading' : ''}`}
								>
									{i18n('loadCertificate')}
								</button>
								{error ? <div className={'error'}>{error}</div> : null}
							</div>
						)}
					</div>
				)}
				<div className={'info__body'}>
					<div className={'info__table'}>
						<div className="info__table-row">
							<div className="info__table-cell">{i18n('infoCounty')}</div>
							<div className="info__table-cell">{data.COUNTRY || '---'}</div>
						</div>

						<div className="info__table-row">
							<div className="info__table-cell">{i18n('infoTons')}</div>
							<div className="info__table-cell">{data.CAPACITY}</div>
						</div>

						<div className="info__table-row">
							<div className="info__table-cell">{i18n('infoInspection')}</div>
							<div className="info__table-cell">{data.SERT_}</div>
						</div>

						<div className="info__table-row">
							<div className="info__table-cell">{i18n('infoMelt')}</div>
							<div className="info__table-cell">{data.HEAT_ || '---'}</div>
						</div>

						<div className="info__table-row">
							<div className="info__table-cell">{i18n('infoShipment')}</div>
							<div className="info__table-cell">
								{dateShip ? new Date(Number(dateShip))?.toLocaleDateString() || '---' : '---'}
							</div>
						</div>

						<div className="info__table-row">
							<div className="info__table-cell">{i18n('infoManufacturer')}</div>
							<div className="info__table-cell">{data.RECEPIENT || '---'}</div>
						</div>

						<div className="info__table-row">
							<div className="info__table-cell">{i18n('infoCountryOfOrigin')}</div>
							<div className="info__table-cell">{data.COUNTORIG || '---'}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
