import React from 'react';
import './styles.scss';
import { useI18n } from '../../Hooks/useI18n';
import WarningIcon from '../../resources/svg/warning.svg';

export const NotGenuine = () => {
	const { i18n } = useI18n();

	return (
		<div className={'container'}>
			<div className={'not-genuine'}>
				<div className="not-genuine__grid">
					<div className="not-genuine__cell">
						<div className="not-genuine__subtitle">
							<h1 className={'h1'}>{i18n('notGenuineTitle')}</h1>
						</div>
						<div className="not-genuine__subtitle">
							<div className={'h3'}>{i18n('notGenuineSubTitle')}</div>
						</div>
					</div>
					<div className="not-genuine__cell">
						<img className={'not-genuine__img'} src={WarningIcon} alt="" />
					</div>
				</div>
			</div>
		</div>
	);
};
