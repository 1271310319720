import React from 'react';
import './resources/scss/index.scss';
import ReactDOM from 'react-dom/client';
import { App } from './Components/App';
import { I18nProvider } from './Providers/I18n';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Error } from './Components/Error';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<I18nProvider>
			<BrowserRouter>
				<Routes>
					<Route path="/" index element={<App />} />
					<Route path="/en" index element={<App />} />
					<Route path="/*" element={<Error />} />
				</Routes>
			</BrowserRouter>
		</I18nProvider>
	</React.StrictMode>
);
