import './styles.scss';
import { LanguageSwitcher } from '../LanguageSwitcher';
import { useLanguage } from '../../Providers/I18n';
import { logoConfig } from '../../Utils/constants/logoConfig';

export const Header = () => {
	const { currentLanguage } = useLanguage();

	return (
		<header className={'header'}>
			<div className={'container'}>
				<div className={'header__inner'}>
					<div className={'header__logo'}>
						<img src={logoConfig[currentLanguage].white} alt="" />
					</div>
					<div className={'header__lang'}>
						<LanguageSwitcher />
					</div>
				</div>
			</div>
		</header>
	);
};
